import moment from 'moment'

var documents = {
  'proposal-en': {
    id: 'proposal-en',
    name: 'Test proposal for {{company}}',
    description: 'Test-sign a proposal with a video',
    background: 'static/img/document-bg.png',
    expiration: moment().add(10, 'hours'),
    language: 'en',
    document_logo: true,
    sender:{
      first_name: 'Dailius',
      last_name: 'Wilson',
      user_thumb: './static/img/dw.jpg'
    },
    recipients: [
      {
        first_name: 'John',
        last_name: 'Doe'
      }
    ],
    video: {
      thumb: './static/video/proposal-en.png',
      url: './static/video/proposal-en.mp4'
    },
    pages: [
      {
        id: 'proposal-en-1',
        url: './static/pages/proposal-en-1.jpg',
      },
      {
        id: 'proposal-en-2',
        url: './static/pages/proposal-en-2.jpg',
      },
      {
        id: 'proposal-en-3',
        url: './static/pages/proposal-en-3.jpg',
      },
      {
        id: 'proposal-en-4',
        url: './static/pages/proposal-en-4.jpg',
      },
      {
        id: 'proposal-en-5',
        url: './static/pages/proposal-en-5.jpg',
      },
      {
        id: 'proposal-en-6',
        url: './static/pages/proposal-en-6.jpg',
      }
    ],
    messages: [
      {
        type: 'received',
        image: './static/video/proposal-en.png',
        url: './static/video/proposal-en.mp4',
        avatar: './static/img/avatarf.png'
      },
      {
        type: 'received',
        text: 'Try asking a question using the chat here - your sales team can talk to customers in real time.',
        datetime: moment().subtract(10, 'seconds'),
        avatar: './static/img/avatarf.png'
      }
    ],
  },
  'proposal-se': {
    id: 'proposal-se',
    name: 'Nice proposal for {{company}}',
    description: 'Test-sign a proposal with a video',
    background: 'static/img/document-bg.png',
    expiration: moment().add(10, 'hours'),
    language: 'sv',
    sender:{
      first_name: 'Dailius',
      last_name: 'Wilson',
      user_thumb: './static/img/dw.jpg'
    },
    recipients: [
      {
        first_name: 'John',
        last_name: 'Doe'
      }
    ],
    video: {
      thumb: './static/video/proposal-en.png',
      url: './static/video/proposal-en.mp4'
    },
    pages: [
      {
        id: 'proposal-se-1',
        url: './static/pages/proposal-se-1.png',
      },
      {
        id: 'proposal-se-2',
        url: './static/pages/proposal-se-2.png',
      },
      {
        id: 'proposal-se-3',
        url: './static/pages/proposal-se-3.png',
      }
    ],
    messages: [
      {
        type: 'received',
        image: './static/video/proposal-en.png',
        url: './static/video/proposal-en.mp4',
        avatar: './static/img/avatarf.png'
      },
      {
        type: 'received',
        text: 'Hi there, \nFeel free to use the chat here :)',
        datetime: moment().subtract(10, 'seconds'),
        avatar: './static/img/avatarf.png'
      }
    ],
  },
  'deck-en': {
    id: 'deck-en',
    name: 'The Best Sales Deck for {{company}}',
    description: 'Check out a sales deck with a video',
    background: 'static/img/document-bg.png',
    expiration: moment().add(10, 'hours'),
    language: 'sv',
    sender:{
      first_name: 'Dailius',
      last_name: 'Wilson',
      user_thumb: './static/img/dw.jpg'
    },
    recipients: [
      {
        first_name: 'John',
        last_name: 'Doe'
      }
    ],
    video: {
      thumb: './static/video/deck-en.png',
      url: './static/video/deck-en.mp4'
    },
    pages: [
      {
        id: 'deck-en-1',
        url: './static/pages/deck-en-1.jpg',
      },
      {
        id: 'deck-en-2',
        url: './static/pages/deck-en-2.jpg',
      },
      {
        id: 'deck-en-3',
        url: './static/pages/deck-en-3.jpg',
      },
      {
        id: 'deck-en-4',
        url: './static/pages/deck-en-4.jpg',
      },
      {
        id: 'deck-en-5',
        url: './static/pages/deck-en-5.jpg',
      },
      {
        id: 'deck-en-6',
        url: './static/pages/deck-en-6.jpg',
      },
      {
        id: 'deck-en-7',
        url: './static/pages/deck-en-7.jpg',
      },
      {
        id: 'deck-en-8',
        url: './static/pages/deck-en-8.jpg',
      },
      {
        id: 'deck-en-9',
        url: './static/pages/deck-en-9.jpg',
      },
      {
        id: 'deck-en-10',
        url: './static/pages/deck-en-10.jpg',
      },
      {
        id: 'deck-en-11',
        url: './static/pages/deck-en-11.jpg',
      },
      {
        id: 'deck-en-12',
        url: './static/pages/deck-en-12.jpg',
      },
      {
        id: 'deck-en-13',
        url: './static/pages/deck-en-13.jpg',
      },
      {
        id: 'deck-en-14',
        url: './static/pages/deck-en-14.jpg',
      },
      {
        id: 'deck-en-15',
        url: './static/pages/deck-en-15.jpg',
      }
    ],
    messages: [
      {
        type: 'received',
        image: './static/video/deck-en.png',
        url: './static/video/deck-en.mp4',
        avatar: './static/img/dw.jpg'
      },
      {
        type: 'received',
        text: 'Try asking a question using the chat here - your sales team can talk to customers in real time.',
        datetime: moment().subtract(10, 'seconds'),
        avatar: './static/img/dw.jpg'
      }
    ],
  },
  'meeting-en': {
    id: 'meeting-en',
    name: 'Meeting Follow Up Guide for {{company}}',
    description: 'Check out our 10 Golden Rules for Meeting Follow ups',
    background: 'static/img/document-bg.png',
    expiration: moment().add(10, 'hours'),
    language: 'en',
    sender:{
      first_name: 'Dailius',
      last_name: 'Wilson',
      user_thumb: './static/img/dw.jpg'
    },
    recipients: [
      {
        first_name: 'John',
        last_name: 'Doe'
      }
    ],
    video: {
      thumb: './static/video/meeting-en.png',
      url: './static/video/meeting-en.mp4'
    },
    pages: [
      {
        id: 'meeting-en-1',
        url: './static/pages/meeting-en-1.jpg',
      },
      {
        id: 'meeting-en-2',
        url: './static/pages/meeting-en-2.jpg',
      },
      {
        id: 'meeting-en-3',
        url: './static/pages/meeting-en-3.jpg',
      },
      {
        id: 'meeting-en-4',
        url: './static/pages/meeting-en-4.jpg',
      },
      {
        id: 'meeting-en-5',
        url: './static/pages/meeting-en-5.jpg',
      },
      {
        id: 'meeting-en-6',
        url: './static/pages/meeting-en-6.jpg',
      },
      {
        id: 'meeting-en-7',
        url: './static/pages/meeting-en-7.jpg',
      },
      {
        id: 'meeting-en-8',
        url: './static/pages/meeting-en-8.jpg',
      },
      {
        id: 'meeting-en-9',
        url: './static/pages/meeting-en-9.jpg',
      },
      {
        id: 'meeting-en-10',
        url: './static/pages/meeting-en-10.jpg',
      },
      {
        id: 'meeting-en-11',
        url: './static/pages/meeting-en-11.jpg',
      },
      {
        id: 'meeting-en-12',
        url: './static/pages/meeting-en-12.jpg',
      },
      {
        id: 'meeting-en-13',
        url: './static/pages/meeting-en-13.jpg',
      }
    ],
    messages: [
      {
        type: 'received',
        image: './static/video/meeting-en.png',
        url: './static/video/meeting-en.mp4',
        avatar: './static/img/dw.jpg'
      },
      {
        type: 'received',
        text: 'Try asking a question using the chat here - your sales team can talk to customers in real time.',
        datetime: moment().subtract(10, 'seconds'),
        avatar: './static/img/dw.jpg'
      }
    ],
  },
  'report-en': {
    id: 'report-en',
    name: 'A Study On The Impact Of Video In Sales',
    description: 'Forecasted by 50 of the World\'s Top Sales Leaders',
    background: 'static/img/document-bg.png',
    expiration: moment().add(10, 'hours'),
    language: 'en',
    document_logo: false,
    sender:{
      first_name: 'Dailius',
      last_name: 'Wilson',
      user_thumb: './static/img/dw.jpg'
    },
    recipients: [
      {
        first_name: 'John',
        last_name: 'Doe'
      }
    ],
    video: {
      thumb: './static/video/report-en.png',
      url: './static/video/report-en.mp4'
    },
    pages: [
      {
        id: 'report-en-1',
        url: './static/pages/report-en-1.jpg',
      },
      {
        id: 'report-en-2',
        url: './static/pages/report-en-2.jpg',
      },
      {
        id: 'report-en-3',
        url: './static/pages/report-en-3.jpg',
      },
      {
        id: 'report-en-4',
        url: './static/pages/report-en-4.jpg',
      },
      {
        id: 'report-en-5',
        url: './static/pages/report-en-5.jpg',
      },
      {
        id: 'report-en-6',
        url: './static/pages/report-en-6.jpg',
      },
      {
        id: 'report-en-7',
        url: './static/pages/report-en-7.jpg',
      },
      {
        id: 'report-en-8',
        url: './static/pages/report-en-8.jpg',
      },
      {
        id: 'report-en-9',
        url: './static/pages/report-en-9.jpg',
      },
      {
        id: 'report-en-10',
        url: './static/pages/report-en-10.jpg',
      },
      {
        id: 'report-en-11',
        url: './static/pages/report-en-11.jpg',
      }
    ],
    messages: [
      {
        type: 'received',
        image: './static/video/report-en.png',
        url: './static/video/report-en.mp4',
        avatar: './static/img/avatarf.png'
      },
      {
        type: 'received',
        text: 'Hi there, \nFeel free to use the chat here :)',
        datetime: moment().subtract(10, 'seconds'),
        avatar: './static/img/avatarf.png'
      }
    ]
  }
}
export default documents;
