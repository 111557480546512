const messages = {
  en: {
    download: 'Download',
    print: 'Print',
    forward: 'Forward',
    writemessage: 'Write a message here',
    signdocument: 'Sign document',
    poweredby: 'Powered by GetAccept',
    poweredwebsite: 'https://www.getaccept.com',
    signpart1: 'Please add your signature above and fill out your email address to test-sign the document',
    signpart2: 'and receive a copy on you email.',
    enteryouremail: 'Please enter your email address',
    invalidemail: 'Invalid email',
    invaliddocument: 'Invalid document',
    pleasetryagain: 'Please try again',
    sorry: 'Sorry',
    error: 'Error',
    businessemail: 'The email address you added can not be recognized, please add your business email to get the full experiences',
    printingnotavailable: 'Printing is not available in this demo',
    downloadingnotavailable: 'Downloading is not available in this demo',
    novideosupport: 'Your browser does not support the video tag.',
    closevideo: 'Close video',
    callsender: 'Call sender',
    rejectdocument: 'Reject document',
    openchat: 'Open chat',
    forwarddocument: 'Forward document',
    forwardhelp: 'Do you want someone else to view the document?',
    forwardinvite: 'Just invite them.',
    forwardsubject: 'Check out this document',
    forwardbody: 'Take a look at this demo document from GetAccept that showcase how you can use a smart B2B solution for sharing and e-signing documents and contracts with others.',
    firstname: 'First name',
    lastname: 'Last name',
    email: 'Email',
    send: 'Send',
    congratulations: 'Congratulations!',
    youhavejoined: 'You have joined {sign_counter} other people who have signed a document with GetAccept.',
    documentwassigned: 'Document was signed {signed_date}',
    enjoyedexperience: 'Did you enjoy the experience?',
    learnmore: 'Learn more',
    learnmorelink: 'https://www.getaccept.com/?ref=demosign',
    freeaccount: 'Create free account',
    freeaccountlink: 'https://www.getaccept.com/free-trial?ref=demosign',
    istyping: 'is typing'
  },
  sv: {
    download: 'Ladda ner',
    print: 'Skriv ut',
    forward: 'Vidarebefordra',
    writemessage: 'Skriv ett meddelande här',
    signdocument: 'Signera dokument',
    poweredby: 'Skapat av GetAccept',
    poweredwebsite: 'https://www.getaccept.se',
    signpart1: 'Prova att fyll i en handsignatur ovan och fyll i din epostadress för att prov-signera dokumentet',
    signpart2: 'och ta emot en kopia.',
    enteryouremail: 'Fyll i din epostadress här',
    invalidemail: 'Ogiltig epost',
    invaliddocument: 'Ogiltigt dokument',
    pleasetryagain: 'Prova igen',
    sorry: 'Hoppsan',
    error: 'Fel',
    businessemail: 'Epostadressen verkar inte fungera. Vänligen ange din jobb-epost för att få rätt upplevelse',
    printingnotavailable: 'Utskrifter är inte tillgängliga i denna demo',
    downloadingnotavailable: 'Nedladdning är inte aktiverat för denna demo',
    novideosupport: 'Din webbläsare kunde inte visa videon.',
    closevideo: 'Stäng videon',
    callsender: 'Ring upp avsändaren',
    rejectdocument: 'Avböj dokumentet',
    openchat: 'Öppna chatten',
    forwarddocument: 'Vidarebefordra dokument',
    forwardhelp: 'Vill du skicka vidare dokumentet till någon annan?',
    forwardinvite: 'Fyll i uppgifterna nedan och skicka.',
    forwardsubject: 'Kolla detta exempeldokument',
    forwardbody: 'Titta gärna på detta exempeldokument som är skickat med GetAccept. Det visar hur man skulle kunna använda deras lösning för att visa, dela och e-signera dokument och kontrakt med andra.',
    firstname: 'Förnamn',
    lastname: 'Efternamn',
    email: 'Epost',
    send: 'Skicka',
    congratulations: 'Grattis!',
    youhavejoined: 'Du är nu en av {sign_counter} andra som har signerat ett dokument med GetAccept.',
    documentwassigned: 'Dokumentet signerades {signed_date}',
    enjoyedexperience: 'Gillade du upplevelsen?',
    learnmore: 'Läs mer',
    learnmorelink: 'https://www.getaccept.com/sv/?ref=demosign',
    freeaccount: 'Skapa gratis testkonto',
    freeaccountlink: 'https://www.getaccept.com/sv/prova-gratis?ref=demosign',
    istyping: 'skriver'
  }
}
export { messages }
