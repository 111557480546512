
import HomePage from '../pages/home.vue';
import ListPage from '../pages/list.vue';

var routes = [
  {
    path: '/',
    component: ListPage
  },
  {
    path: '/:documentId',
    component: HomePage
  }
];

export default routes;
