<template>
  <f7-page name="home" :class="loading_class">
    <f7-popup
      class="video-popup"
      push
      closeOnEscape
      closeByBackdropClick
      swipeToClose
      v-if="document.video"
    >
      <f7-page>
        <f7-button fill large rounded popup-close>{{$t('closevideo')}}</f7-button>
        <div class="video-layer" @click="playVideo()"></div>
        <video
          width="100%"
          height="100%"
          style="max-height:100%;max-width:100%;"
          autoplay="autoplay"
          controls="false"
          id="intro-video"
        >
          <source src type="video/mp4" />
          {{$t('novideosupport')}}
        </video>
      </f7-page>
    </f7-popup>
    <f7-row class="document-wrapper">
      <f7-col width="0" large="75" class="desktop">
        <div class="document-page" v-for="(page, index) in document.pages" :key="index">
          <div v-if="index==0 && document.document_logo" class="document-logo">
            <img :src="show_logo" crossorigin="anonymous" />
          </div>
          <div class="document-page-content">
            <img :src="page.url" />
          </div>
        </div>
        <div class="document-powered">
          <f7-link :href="$t('poweredwebsite')" target="_blank" external>{{$t('poweredby')}}</f7-link>
        </div>
      </f7-col>
      <f7-col class="rightbar desktop" width="0" large="25">
        <div class="document-info">
          <div class="logo">
            <img
              :src="show_logo"
              onerror="this.onerror=null;this.src='static/img/logo-inverted.png';"
              crossorigin="anonymous"
            />
          </div>

          <div class="document-name">{{document_name}}</div>

          <div
            class="document-page_count"
            v-if="false"
          >{{page_count}} {{page_count > 1 ? 'pages' : 'page'}}</div>

          <div
            class="document-expiration"
            v-if="false"
          >Expires {{relativeDate(document.expiration)}}</div>

          <div
            class="document-recipient_count"
            v-if="false"
          >{{recipient_count}} {{recipient_count > 1 ? 'recipients' : 'recipient'}}</div>

          <f7-row class="document-actions">
            <f7-col>
              <f7-link icon="icon-download" :text="$t('download')" @click="downloadDocument()"></f7-link>
            </f7-col>
            <f7-col>
              <f7-link icon="icon-print" :text="$t('print')" @click="printDocument()"></f7-link>
            </f7-col>
            <f7-col>
              <f7-link icon="icon-send" :text="$t('forward')" sheet-open=".forward-sheet"></f7-link>
            </f7-col>
          </f7-row>
        </div>

        <div class="chat desktop">
          <div class="chat-messages">
            <!-- <i>No messages</i> -->
            <f7-messages ref="messagelist" :scrollMessages="false">
              <f7-message
                v-for="(message, index) in document.messages"
                :key="index"
                :type="message.type"
                :avatar="message.avatar"
                first
                last
                tail
                :typing="false"
              >
                <div v-if="message.image" class="message-image" slot="image" @click="loadVideo()">
                  <div class="video-layer"></div>
                  <img :src="message.image" />
                </div>
                <span v-if="message" slot="footer" v-html="fixMessageFooter(message)"></span>
                <span v-if="message.text" slot="text" v-html="message.text"></span>
              </f7-message>
              <f7-message
                v-if="typingMessage"
                type="received"
                :typing="true"
                :first="true"
                :last="true"
                :tail="true"
                :header="`${document.sender.first_name} `+$t('istyping')"
                :avatar="document.sender.user_thumb"
              ></f7-message>
            </f7-messages>
          </div>
          <div class="messagebar">
            <f7-messagebar
              ref="messagebar"
              @input="checkMessagebar($event)"
              :placeholder="$t('writemessage')"
            >
              <f7-link icon="icon-forward" slot="inner-end" @click="sendMessage"></f7-link>
            </f7-messagebar>
          </div>
        </div>

        <f7-row class="sign-actions">
          <f7-col>
            <f7-button v-show="!signed" fill large @click="openSign">{{$t('signdocument')}}</f7-button>
            <f7-button v-show="false" color="red" @click="rejectConfirm">Reject document</f7-button>
            <f7-block
              class="text-align-center"
              v-show="signed"
            >{{$t('documentwassigned', {signed_date: signed_date})}}</f7-block>
          </f7-col>
        </f7-row>
      </f7-col>
      <f7-col class="mobile">
        <f7-toolbar tabbar top class="menu-mobile">
          <f7-link tab-link="#document-tab" icon="icon-document" tab-link-active>Document</f7-link>
          <f7-link tab-link="#chat-tab" icon="icon-chat">Chat</f7-link>
          <f7-link tab-link="#forward-tab" icon="icon-send">Forward</f7-link>
          <f7-link tab-link="#menu-tab" icon="icon-collapse">Menu</f7-link>
        </f7-toolbar>
        <f7-tabs animated>
          <f7-tab id="document-tab" class="mobile-page page-content" tab-active>
            <div
              v-show="!messagebarVisible"
              class="document-page"
              v-for="(page, index) in document.pages"
              :key="index"
            >
              <div v-if="index==0 && document.document_logo" class="document-logo">
                <img :src="show_logo" crossorigin="anonymous" />
              </div>
              <div class="document-page-content">
                <img :src="page.url" />
              </div>
            </div>
            <div class="document-powered">
              <f7-link :href="$t('poweredwebsite')" target="_blank" external>{{$t('poweredby')}}</f7-link>
            </div>
            <div class="sign-footer">
              <f7-row class="sign-actions">
                <f7-col>
                  <f7-button v-show="!signed" fill large @click="openSign">{{$t('signdocument')}}</f7-button>
                  <f7-button v-show="false" color="red" @click="rejectConfirm">Reject document</f7-button>
                  <f7-block
                    class="text-align-center"
                    v-show="signed"
                  >{{$t('documentwassigned', {signed_date: signed_date})}}</f7-block>
                </f7-col>
              </f7-row>
            </div>
          </f7-tab>
          <f7-tab id="chat-tab" class="mobile-page chat page-content">
            <div class="block inset chat-messages">
              <!-- <i>No messages</i> -->
              <f7-messages ref="messagelist">
                <f7-message
                  v-for="(message, index) in document.messages"
                  :key="index"
                  :type="message.type"
                  :avatar="message.avatar"
                  first
                  last
                  tail
                  :typing="false"
                >
                  <div v-if="message.image" class="message-image" slot="image" @click="loadVideo()">
                    <div class="video-layer"></div>
                    <img :src="message.image" />
                  </div>
                  <span slot="footer" v-html="fixMessageFooter(message)"></span>
                  <span slot="text" v-if="message.text" v-html="message.text"></span>
                </f7-message>
                <f7-message
                  v-if="typingMessage"
                  type="received"
                  :typing="true"
                  :first="true"
                  :last="true"
                  :tail="true"
                  :header="`${document.sender.first_name} `+$t('istyping')"
                  :avatar="document.sender.user_thumb"
                ></f7-message>
              </f7-messages>
            </div>
          </f7-tab>
          <f7-tab id="forward-tab" class="mobile-page page-content">
            <f7-block inset strong>
              <f7-block-title medium>{{$t('forwarddocument')}}</f7-block-title>
              {{$t('forwardhelp')}}
              <br />
              {{$t('forwardinvite')}}
            </f7-block>
            <f7-list inset no-hairlines>
              <f7-list-input
                type="text"
                @input="forward_first_name=$event.target.value"
                :label="$t('firstname')"
              ></f7-list-input>
              <f7-list-input
                type="text"
                @input="forward_last_name=$event.target.value"
                :label="$t('lastname')"
              ></f7-list-input>
              <f7-list-input
                type="email"
                @input="forward_email=$event.target.value"
                :label="$t('email')"
                :value="email"
              ></f7-list-input>
            </f7-list>
            <f7-block inset>
              <f7-button fill large @click="forwardDocument">{{$t('send')}}</f7-button>
            </f7-block>
          </f7-tab>
          <f7-tab id="menu-tab" class="mobile-page page-content">
            <f7-block inset strong>
              <f7-block-title medium>{{document_name}}</f7-block-title>
              <div class="info-logo">
                <img
                  :src="show_logo"
                  onerror="this.onerror=null;this.src='static/img/logo-inverted.png';"
                  crossorigin="anonymous"
                />
              </div>
              <f7-list no-hairlines>
                <f7-list-item :title="$t('openchat')" link @click="openTab('#chat-tab')">
                  <f7-icon slot="media" icon="icon-chat"></f7-icon>
                </f7-list-item>
                <f7-list-item :title="$t('forwarddocument')" link @click="openTab('#forward-tab')">
                  <f7-icon slot="media" icon="icon-send"></f7-icon>
                </f7-list-item>
                <f7-list-item :title="$t('callsender')" disabled link>
                  <f7-icon slot="media" icon="icon-phone"></f7-icon>
                </f7-list-item>
                <f7-list-item :title="$t('download')" disabled link>
                  <f7-icon slot="media" icon="icon-download"></f7-icon>
                </f7-list-item>
                <f7-list-item :title="$t('rejectdocument')" disabled link>
                  <f7-icon slot="media" icon="icon-reject"></f7-icon>
                </f7-list-item>
              </f7-list>
            </f7-block>
          </f7-tab>
        </f7-tabs>
      </f7-col>
    </f7-row>
    <f7-messagebar
      class="messagebarmobile"
      ref="messagebarmobile"
      @input="checkMessagebar($event)"
      :placeholder="$t('writemessage')"
      v-show="messagebarVisible"
    >
      <f7-link icon="icon-forward" slot="inner-end" @click="sendMessage"></f7-link>
    </f7-messagebar>

    <f7-sheet
      class="forward-sheet"
      style="height:auto; --f7-sheet-bg-color: #fff;"
      closeOnEscape
      backdrop
    >
      <div class="sheet-modal"></div>
      <f7-block no-hairlines>
        <f7-block-title large>{{$t('forwarddocument')}}</f7-block-title>
        {{$t('forwardhelp')}}
        <br />
        {{$t('forwardinvite')}}
      </f7-block>
      <f7-list no-hairlines>
        <f7-list-input
          type="text"
          @input="forward_first_name=$event.target.value"
          :label="$t('firstname')"
        ></f7-list-input>
        <f7-list-input
          type="text"
          @input="forward_last_name=$event.target.value"
          :label="$t('lastname')"
        ></f7-list-input>
        <f7-list-input
          type="email"
          @input="forward_email=$event.target.value"
          :label="$t('email')"
        ></f7-list-input>
      </f7-list>
      <f7-block inset>
        <f7-button fill large @click="forwardDocument">{{$t('send')}}</f7-button>
      </f7-block>
    </f7-sheet>
    <f7-sheet
      class="sign-sheet"
      style="height:auto; --f7-sheet-bg-color: #fff;"
      closeOnEscape
      backdrop
    >
      <div class="sheet-modal"></div>
      <div>
        <f7-block-title medium class="margin-top">{{$t('signdocument')}}</f7-block-title>
        <VueSignaturePad
          :width="padOptions.width"
          :height="padOptions.height"
          id="signature"
          ref="signaturePad"
          :options="padOptions"
        />

        <f7-block class="sign-confirm-text" no-hairlines>
          {{$t('signpart1')}}
          <b>{{document_name}}</b>
          {{$t('signpart2')}}
        </f7-block>
        <f7-block>
          <f7-input
            class="sign-email"
            outline
            validate
            @input="email=$event.target.value"
            :placeholder="$t('enteryouremail')"
            :value="email"
            type="email"
          ></f7-input>
        </f7-block>
        <f7-block>
          <div class="preloader" v-show="posting"></div>
          <f7-button fill large v-show="!posting" @click="sendSign()">{{$t('signdocument')}}</f7-button>
        </f7-block>
      </div>
    </f7-sheet>

    <f7-sheet
      class="thanks-sheet"
      style="height:auto; --f7-sheet-bg-color: #fff;"
      closeOnEscape
      backdrop
    >
      <div class="sheet-modal"></div>
      <div>
        <f7-block-title large class="margin-top">{{$t('congratulations')}}</f7-block-title>
        <f7-block>
          <h2>{{$t('youhavejoined', { sign_counter: sign_counter })}}</h2>
          <h3>{{$t('enjoyedexperience')}}</h3>
          <f7-row class="readmore">
            <f7-col width="40">
              <f7-button
                fill
                :href="$t('learnmorelink')"
                external
                target="_blank"
              >{{$t('learnmore')}}</f7-button>
            </f7-col>
            <f7-col width="60">
              <f7-button
                fill
                :href="$t('freeaccountlink')"
                external
                target="_blank"
              >{{$t('freeaccount')}}</f7-button>
            </f7-col>
          </f7-row>
        </f7-block>
      </div>
    </f7-sheet>
  </f7-page>
</template>
 
  

<script>
import moment from 'moment'
import documents from '../js/documents.js'
import axios from 'axios'
import ColorThief from 'colorthief'



export default {
  components: {
  },
  data() {
    return {
      documentId: null,
      messagebarVisible: false,
      loading: true,
      email: '',
      domain: null,
      company: 'Company X',
      logo: null,
      fallback_logo: null,
      forward_email: null,
      forward_first_name: null,
      forward_last_name: null,
      posting: false,
      typingMessage: false,
      chatContexts: [],
      signed: false,
      signCounter: 0,
      sessionTime: localStorage.getItem("sessionTime") > 0 ? localStorage.getItem("sessionTime") : null,
      signedDate: null,
      document: {
        name: null,
        recipients: [],
        pages: [],
        messages: [],
      },

      documents: documents,
      padOptions: {
        penColor: "#333333",
        width: '99.5%',
        height: (window.innerWidth > 600 ? '150px' : '100px'),
        onBegin: () => {
          this.$refs.signaturePad.resizeCanvas()
        }
      }
    }
  },
  computed: {
    recipient_count() {
      return this.document.recipients.length
    },
    page_count() {
      return this.document.pages.length
    },
    document_name() {
      if (this.document.name) {
        return this.document.name.replace('{{company}}', this.company)
      }
      else {
        return ''
      }
    },
    loading_class() {
      return this.loading ? 'loading' : null
    },
    signed_date() {
      return moment(this.signedDate).fromNow()
    },
    sign_counter() {
      return this.signCounter.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    show_logo() {
      if (this.fallback_logo) {
        return this.fallback_logo
      }
      else if (!this.domain) {
        return 'static/img/logo-inverted.png'
      }
      else {
        return this.logo
      }
    }
  },
  watch: {
    document() {
      if (this.document.background && window.innerWidth > 600) {
        let style = this.$$('.document-wrapper')[0].style
        style.backgroundImage = 'url(' + this.document.background + ')'
        style.backgroundAttachment = 'fixed'
      }
    },
    domain() {
      if (this.domain != '') {
        this.getLogo()
        this.getCompany()
      }
    },
    document_name() {
      document.title = this.document_name
    }
  },
  methods: {
    relativeDate(datetime) {
      return moment(datetime).fromNow()
    },
    fixMessageFooter(message) {
      if (this.document) {
        var name = this.document.recipients[0].first_name + ' ' + this.document.recipients[0].last_name
        if (message.type == 'received') {
          name = this.document.sender.first_name + ' ' + this.document.sender.last_name
        }
        return '<b>' + name + '</b> ' + moment(message.datetime).fromNow()
      }
    },
    checkMessagebar(event) {
      if (event.inputType == 'insertLineBreak') {
        this.sendMessage()
      }
    },
    sendMessage() {
      if (this.$refs.messagebar) {
        if (window.matchMedia("(max-width: 900px)").matches) {
          //mobile
          this.messagebar = this.$refs.messagebarmobile.f7Messagebar
        }
        else {
          this.messagebar = this.$refs.messagebar.f7Messagebar
        }
      }
      const text = this.messagebar.getValue().replace(/\n/g, '<br>').trim();
      if (!text) {
        return false
      }
      this.document.messages.push({
        type: 'sent',
        name: 'John Doe',
        text: text.linkify(),
        datetime: moment(),
        avatar: './static/img/avatarm.png'
      })
      this.receiveAnswer(text)
      this.messagebar.clear()
      this.scrollChat()
    },
    scrollChat() {
      var chatmessages = this.$$('.chat .chat-messages')
      if (window.matchMedia("(max-width: 900px)").matches) {
        //mobile
        chatmessages = this.$$('.page-content')
      }

      setTimeout(function () {
        if (chatmessages.length > 0) {
          chatmessages[0].scrollTop = chatmessages[0].scrollHeight
        }
      }, 150)
    },
    receiveAnswer(text) {
      const self = this
      let url = 'https://us-central1-ga-sign.cloudfunctions.net/chatbot'
      let config = {}
      let payload = {
        sessionId: this.sessionTime,
        queryInput: {
          text: {
            text: text,
            languageCode: 'en-US'
          }
        }
      }
      axios.post(url, payload, config)
        .then(res => {
          if (res.data) {
            // this.chatContexts = res.data.outputContexts ? res.data.outputContexts : null
            self.typingMessage = true
            self.scrollChat()
            let chatResponse = res.data.response
            var chatDelay = chatResponse.length * 50
            if (chatDelay > 5000) {
              chatDelay = 5000
            }
            setTimeout(function () {
              self.document.messages.push({
                type: 'received',
                name: 'Jane Doe',
                text: chatResponse.linkify(),
                datetime: moment(),
                avatar: './static/img/avatarf.png'
              })
              self.scrollChat()
              self.typingMessage = false
            }, chatDelay)
          }
        })
    },
    openTab(tabId) {
      const app = this.$f7;
      app.tab.show(tabId);
    },
    rejectConfirm() {
      const app = this.$f7;
      app.dialog.confirm('Are you sure you want to reject the document?', 'Reject document?', () => {
        app.dialog.alert('The sender has been notified.', 'Sorry to hear');
      });
    },
    loadDocument() {
      const self = this
      if (this.documentId in this.documents) {
        this.document = this.documents[this.documentId]
        if (this.document.video) {
          setTimeout(function () {
            self.loadVideo()
          }, 2000)
        }
        self.loading = false
      }
      else {
        this.$f7.dialog.alert(this.$t('pleasetryagain'), this.$('invaliddocument'));
      }

    },
    postForm() {
      const app = this.$f7;
      const self = this
      this.posting = true
      let url = 'https://api.hsforms.com/submissions/v3/integration/submit/541808/4660795b-87f6-47af-80f6-3091a253bbb4'
      let payload = {
        'fields': [
          {
            'name': 'email',
            'value': this.email
          },
          {
            'name': 'firstname',
            'value': ''
          }
        ],
        'context': {
          'pageUri': document.location.href,
          'pageName': document.title
        },
      }
      axios.post(url, payload)
        .then(response => {
          self.posting = false
          self.signed = true
          self.signedDate = moment()
          if (response.data && response.data.inlineMessage) {
            app.actions.open('.thanks-sheet')
            this.loadIntercom()
            this.loadCounter()
          }
        })
        .catch(error => {
          if (error.response.data.errors && error.response.data.errors[0].errorType == 'INVALID_EMAIL') {
            app.dialog.alert(this.$t('businessemail'), this.$t('error'));
            app.actions.open('.sign-sheet')
          }
          else if (error.response.data.errors && error.response.data.errors[0].message) {
            app.dialog.alert(error.response.data.errors[0].message, this.$t('error'));
          }
          self.posting = false
        })
    },
    openSign() {
      const app = this.$f7;
      const self = this
      app.actions.open('.sign-sheet')
      setTimeout(function () {
        if (self.$$('#signature').length > 0) {
          self.$$('#signature')[0].style.backgroundImage = 'none'
        }
      }, 5000)
    },
    testSign() {
      const app = this.$f7;
      app.actions.close('.sign-sheet')
      app.actions.open('.thanks-sheet')
      this.loadIntercom()
      this.loadCounter()
    },
    sendSign() {
      const app = this.$f7;
      if (this.email.length > 3 && this.$$('.sign-sheet .input-invalid').length == 0) {
        app.actions.close('.sign-sheet')
        this.postForm()
      }
      else {
        this.$f7.dialog.alert(this.$t('pleasetryagain'), this.$t('invalidemail'));
      }
    },
    forwardDocument() {
      const app = this.$f7;
      if (this.forward_email.length > 3) {
        let mailto = this.forward_first_name + ' ' + this.forward_first_name + ' <' + this.forward_email + '>'
        let subject = this.$t('forwardsubject')
        let body = this.$t('forwardbody') + '\n\n' + window.document.location
        let url = 'mailto:' + encodeURIComponent(mailto) + '?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body)
        window.open(url)
        app.actions.close('.forward-sheet')
      }
      else {
        this.$f7.dialog.alert(this.$t('pleasetryagain'), this.$t('invalidemail'));
      }
    },
    printDocument() {
      this.$f7.dialog.alert(this.$t('printingnotavailable'), this.$t('sorry'));
    },
    downloadDocument() {
      this.$f7.dialog.alert(this.$t('downloadingnotavailable'), this.$t('sorry'));
    },
    loadVideo() {
      const self = this
      if (!this.document.video) {
        return false
      }

      this.$$(".video-popup #intro-video")[0].addEventListener("ended", function () {
        self.$f7.popup.close('.video-popup')
        self.video_end = new Date().getTime()
      })
      this.$$(".video-popup video")[0].addEventListener("play", function () {
        self.video_start = new Date().getTime()
        if (self.$$(".video-popup .video-layer").length > 0) {
          self.$$(".video-popup .video-layer")[0].remove()
        }
      })

      this.$$(".video-popup video")[0].addEventListener("loadstart", function () {
      })
      this.$$(".video-popup video")[0].addEventListener("loadeddata", function () {
        self.$f7.popup.open('.video-popup')
        // self.$$('.video-popup')[0].style.height = self.$$(".video-popup #intro-video")[0].clientHeight+'px'
      })

      this.$$('.video-popup').on('popup:close', function (e) {
        if (!self.$$(".video-popup #intro-video")[0].paused) {
          self.$$(".video-popup #intro-video")[0].pause()
        }
      })
      this.$$(".video-popup #intro-video")[0].poster = this.document.video.thumb
      this.$$(".video-popup #intro-video")[0].src = this.document.video.url

    },
    playVideo() {
      // this.$$(".video-popup .video-layer")[0].remove()
      if (this.$$(".video-popup #intro-video")[0].paused) {
        this.$$(".video-popup #intro-video")[0].play()
      }
    },
    loadIntercom() {
      // console.log(this.$intercom)
      this.$intercom.boot({
        utm_campaign: 'demosign',
        hide_default_launcher: true,
        vertical_padding: 160
      })
      this.$intercom.update({ 'registration_email': this.email, 'demosign': new Date() })
      // this.$intercom.show()
    },
    loadCounter() {
      this.signCounter++
      setTimeout(this.loadCounter, 1000 + Math.random() * 5000)
    },
    getLogo() {
      const self = this
      var logo_image = new Image()
      logo_image.src = 'http://logo.clearbit.com/' + encodeURIComponent(this.domain)
      logo_image.addEventListener('load', function () {
        self.logo = logo_image.src
        self.$$('.rightbar .document-info')[0].style.backgroundColor = '#ffffff'
        self.getColors(1)
      })
      logo_image.addEventListener('error', function () {
        self.fallback_logo = 'static/img/logo-inverted.png'
      })
    },
    getCompany() {
      let regex = /([a-z0-9\-]+)(?:\.[a-z\.]+[\/]?).*/
      const found = this.domain.match(regex);
      let company_name = found.length > 1 ? found[1] : ''
      if (company_name.length > 4) {
        company_name = company_name.toLowerCase().replace(/([^a-z])([a-z])(?=[a-z]{2})|^([a-z])/g, function (_, g1, g2, g3) {
          return (typeof g1 === 'undefined') ? g3.toUpperCase() : g1 + g2.toUpperCase();
        })
      }
      else {
        company_name = company_name.toUpperCase()
      }
      if (company_name) {
        this.company = company_name
      }
    },
    getColors(retry) {
      const self = this
      const colorThief = new ColorThief();
      const img = document.querySelector('.logo img');

      if (img.complete && img.width > 0 || retry > 5) {
        var colors = colorThief.getPalette(img)
      }
      else {
        setTimeout(function () {
          retry++
          self.getColors(retry)
        }, 50)
        return
      }
      const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
        const hex = x.toString(16)
        return hex.length === 1 ? '0' + hex : hex
      }).join('')

      const tint = function (col) {
        return col < 230 ? col + 20 : col
      }

      //find best color
      var bestColor = -1;
      for (let i = 0; i < colors.length; i++) {
        let sum = colors[i].reduce(function (a, b) { return a + b; }, 0)
        // console.log(colors[i].length, i, sum, colors[i])
        if (colors[i].length > 1 && sum > 20 && (sum < 400)) {
          bestColor = i
          break
        }
      }
      if (bestColor > -1) {
        let accentColor = rgbToHex(colors[bestColor][0], colors[bestColor][1], colors[bestColor][2])
        let tintColor = rgbToHex(tint(colors[bestColor][0]), tint(colors[bestColor][1]), tint(colors[bestColor][2]))
        document.documentElement.style.setProperty('--f7-theme-color', accentColor)
        document.documentElement.style.setProperty('--f7-theme-color-rgb', colors[bestColor][0], colors[bestColor][1], colors[bestColor][2])
        document.documentElement.style.setProperty('--f7-theme-color-tint', tintColor)
      }
      this.showDocumentLogo()
    },
    showDocumentLogo() {
      if (this.logo && this.document.document_logo) {

      }
    }
  },
  mounted() {
    const self = this
    this.$$(this.$el).parents('.view').off('tab:show').on('tab:show', function (tab) {
      if (tab.target.id == 'document-tab') {
        self.$$('.tabs-animated-wrap .tabs')[0].style = ''
        self.messagebarVisible = false
        // self.$$('.page-content')[0].scrollTop = 0
        self.$$('.page-content')[0].style.height = '100%'
      }
      else if (tab.target.id == 'chat-tab') {
        self.messagebarVisible = true
        // self.$$('.page-content')[0].scrollTop = self.$$('.page-content')[0].scrollHeight
        self.$$('.page-content')[0].style.height = '100%'
      }
      else {
        self.messagebarVisible = false
        self.$$('.page-content')[0].scrollTop = 0
        if (window.innerHeight > 500) {
          self.$$('.page-content')[0].style.height = 'auto'
        }
      }
    })

    let param = this.$f7route.params.documentId
    this.documentId = param ? param : 'test'
    this.loadDocument()

    var urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('d')) {
      this.domain = urlParams.get('d')
    }
    if (urlParams.get('l')) {
      let userLocale = urlParams.get('l')
      this.$i18n.locale = userLocale
      moment.locale(userLocale)
    }
    if (urlParams.get('e')) {
      this.email = urlParams.get('e')
    }

    if (this.$refs.messagelist) {
      this.messagelist = this.$refs.messagelist.f7Messages
    }
  },
  created() {
    if (this.sessionTime && ((Date.now() - this.sessionTime) / 1000 / 60 > 10)) {
      this.sessionTime = null //Reset expired session after 10 minutes
    }
    if (!this.sessionTime) {
      this.sessionTime = Date.now()
      localStorage.setItem("sessionTime", this.sessionTime);
    }
    this.signCounter = Math.round((Date.now() - 1579500000000) / 1000)
  }
}
</script>
