<template>
  <f7-page name="documentlist">
    <f7-block-title large>Demo Sign Documents</f7-block-title>

    <f7-block>
      <f7-card class="list-card" v-for="(document,index) in documents" :key="index">
        <f7-card-header
          class="no-border"
          valign="bottom"
          :style="`background-image:url(`+document.pages[0].url+`)`"
        ><f7-link :href="`/`+document.id">{{document.name}}</f7-link></f7-card-header>
        <f7-card-content>
          <p>{{document.description}}</p>
        </f7-card-content>
        <f7-card-footer>
          <f7-link :href="`/`+document.id">Show document</f7-link>
        </f7-card-footer>
      </f7-card>
    </f7-block>
    

    <!-- <f7-list media-list v-if="Object.keys(documents).length > 0">
      <f7-list-item
        v-for="(document,index) in documents"
        :key="index"
        :link="`/`+document.id"
        :title="document.name"
        :text="document.description"
      >
        <img slot="media" :src="document.pages[0].url" width="120" />
      </f7-list-item>
    </f7-list> -->

    <!-- <f7-col width="20" inset >
        <img :src="document.pages[0].url" width="100" />
        <f7-button fill large round :href="`/`+document.id">{{document.name}}</f7-button>
    </f7-col>-->
  </f7-page>
</template>


<script>
import documents from '../js/documents.js'
export default {
  data() {
    return {
      documents: documents
    }
  },
  created() {
    document.title = 'Select document';
  }
}
</script>