// Import Vue
import Vue from 'vue';
Vue.config.productionTip = false;

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles

import '../css/app.css';
import '../css/fonts.css';

// Import App Component
import App from '../components/app.vue';

let locales = ['en', 'sv'];
let userLocale = navigator.language.substr(0, 2)
if (!locales.includes(userLocale)) {
  userLocale = 'en'
}

// Import Translations
import { messages } from './translations'
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: userLocale, // set locale
  messages, // set locale messages
});

import moment from 'moment';
moment.locale(userLocale)

import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);

import VueIntercom from 'vue-intercom'
Vue.use(VueIntercom, { appId: 'g2tc8sjs' })

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),

  // Register App Component
  components: {
    app: App
  },
  i18n
});

if (!String.linkify) {
  String.prototype.linkify = function () {
    // http://, https://, ftp://
    var urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;
    // www. sans http:// or https://
    var pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    // Email addresses
    var emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;
    return this
      .replace(urlPattern, '<a href="$&" class="link external" target="_blank">$&</a>')
      .replace(pseudoUrlPattern, '$1<a href="http://$2" class="link external" target="_blank">$2</a>')
      .replace(emailAddressPattern, '<a href="mailto:$&" class="link external" target="_blank">$&</a>');
  };
}